<template>
  <div>
    <v-btn class="ml-2" icon x-small @click="openDialog">
      <v-icon color="neuter"> mdi-cog</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent width="800px">
      <v-card>
        <v-card-title class="headline secondary white--text">{{ $t('common.titles.headers') }}</v-card-title>
        <v-card-text class="pa-6">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="localSelected"
                :disabled="!(availableColumns && availableColumns.length > 0)"
                :items="availableColumns"
                :label="$t('common.titles.headers')"
                background-color="#EAEAEA80"
                color="primary"
                flat
                item-color="primary"
                item-text="name"
                item-value="key"
                multiple
                return-object
                small
                solo
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                      (+{{ localSelected.length - 1 }}
                      {{ $t('common.titles.others') }})
                    </span>
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 360px">
                    <v-checkbox
                      :hide-details="true"
                      :input-value="attrs.inputValue"
                      class="pt-2 pb-2 mt-0"
                      color="primary"
                    >
                      <template v-slot:label>
                        <read-more
                          :classes="'mb-0'"
                          :font-size="14"
                          :max-chars="80"
                          :text="item.name"
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              class="d-flex flex-wrap justify-start align-start"
              cols="12"
            >
              <v-chip-group
                v-model="selection"
                active-class="primary--text"
                column
              >
                <draggable v-model="localSelected" @end="dragEnd" @start="dragStart">
                  <v-chip
                    v-for="(item, index) in localSelected"
                    :key="index"
                    class="ma-1"
                    close
                    draggable
                    small
                    @click:close="deleteItem(index)"
                  >
                    {{ item.name }}
                  </v-chip>
                </draggable>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-btn
            small text color="neuter"
            @click="resetConfig()"
            :disabled="loadingCancel || loading"
            :loading="loadingReset"
          >
            {{ $t('common.buttons.clean') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :loading="loadingCancel"
            small text color="neuter"
            @click="cancelDialogConfig()"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            :loading="loading"
            small elevation="0" color="primary" class="t-bw-primary--text"
            @click="setExtraColumnsAction()"
          >
            {{ $t('common.buttons.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReadMore from "@/components/ReadMoreComponent.vue";
import draggable from 'vuedraggable'
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  components: {draggable, ReadMore},

  data() {
    return {
      dialog: false,
      loading: false,
      loadingReset: false,
      loadingCancel: false,
      selection: null,
      currentTag: null,
      localSelected: [] // Local copy to prevent reactivity issues
    };
  },

  computed: {
    ...mapGetters({
      columns: 'storage/GET_EXTRA_COLUMNS',
      selected: 'storage/GET_SELECTED_COLUMNS',
    }),
    availableColumns() {
      return this.columns || [];
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        // When opening dialog, make a deep copy to prevent reactivity issues
        this.localSelected = _.cloneDeep(this.selected);
      }
    }
  },

  methods: {
    ...mapActions({
      setExtraColumns: 'storage/setExtraColumns',
      fetchExtraColumns: 'storage/fetchExtraColumns'
    }),
    openDialog() {
      this.dialog = true;
      // Initialize local copy when opening the dialog
      this.localSelected = _.cloneDeep(this.selected);
    },
    async setExtraColumnsAction() {
      this.loading = true;
      
      try {
        // Get the columns in the correct order from the local copy
        const orderedColumns = this.getByProperty(this.localSelected, 'key');
        console.log('Saving ordered columns:', orderedColumns);
        
        // Update store with final selection only when confirming
        await this.setExtraColumns(orderedColumns);
        
        // Close dialog before triggering refresh to avoid conflicts
        this.dialog = false;
        
        // Only after dialog is closed, trigger refresh
        setTimeout(() => {
          this.$root.$emit('refresh-storage-table');
        }, 100);
      } catch (error) {
        console.error('Error saving column configuration:', error);
        this.$toast.error(this.$t('common.notification.error'));
      } finally {
        this.loading = false;
      }
    },
    async resetConfig() {
      this.loadingReset = true;
      try {
        await this.setExtraColumns([]);
        this.localSelected = [];
        this.dialog = false;
        
        setTimeout(() => {
          this.$root.$emit('refresh-storage-table');
        }, 100);
      } catch (error) {
        console.error('Error resetting columns:', error);
      } finally {
        this.loadingReset = false;
      }
    },
    async cancelDialogConfig() {
      this.loadingCancel = true;
      try {
        // Restore original selection without saving
        await this.fetchExtraColumns();
        this.dialog = false;
      } catch (error) {
        console.error('Error canceling:', error);
      } finally {
        this.loadingCancel = false;
      }
    },
    dragStart() {
      if (this.localSelected[this.selection]) {
        this.currentTag = this.localSelected[this.selection].name;
      } else {
        this.currentTag = null;
      }
    },
    dragEnd() {
      let self = this;
      if (this.currentTag) {
        this.localSelected.forEach((x, i) => {
          if (x.name === self.currentTag) self.selection = i;
        });
      }
      
      // No need to update store yet, just keep local changes
    },
    deleteItem(index) {
      // Just modify local copy, don't update store yet
      this.localSelected.splice(index, 1);
    },
    getByProperty(array, property) {
      if (!array || !Array.isArray(array)) return [];
      return array.map(item => item && item[property]).filter(Boolean);
    },
  }
}
</script>

<style scoped>

</style>