<template>
  <v-dialog persistent v-model="dialog" scrollable width="auto">
    <v-card light color="white">
      <v-card-title class="dialog__header">
        <span
          class="headline font-weight-medium"
        >
            {{ $t('documents.titles.versions') }}
          </span>
      </v-card-title>
      <v-divider />
        <v-card-text class="pa-6">
          <v-row justify="space-between" v-if="dialog">
            <v-col cols="12">
              <v-data-table
                class="elevation-0"
                item-key="id"
                show-expand
                sort-by="numberVersion"
                :headers="headers"
                :items="versions"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalFromServer"
                :single-expand="true"
                :no-results-text="$t('common.ui.not_search_found')"
                :no-data-text="$t('common.ui.not_data_found')"
                :loading-text="$t('common.notification.loadData')"
                :footer-props="{
                        ...rowsPerPageItemsOptions,
                        itemsPerPageText: $t('common.table.itemsPerPageText'),
                        pageText:
                          '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                      }"
                @item-expanded="loadVersion($event)"
              >
                <template v-slot:item.active='{ item }'>
                  <v-icon small class='mr-4' color='success' v-if='item.active'>
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <v-icon small class='mr-4' color='metal' v-if='!item.active'>
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td
                    :colspan="headers.length"
                    style="height: 20vh"
                    class="pa-0"
                  >
                    <div class="map">
                      <v-overlay
                        :value="!ready[item.id]"
                        absolute
                        light
                        color="white"
                        opacity=".8"
                      >
                        <v-progress-circular
                          indeterminate
                          size="64"
                          color="primary"
                        />
                      </v-overlay>
                      <map-box
                        :displayDrawControlsDefault="false"
                        :drawControl="true"
                        :drawControls="controls"
                        :geolocateControl="true"
                        :mapConfiguration="mapConfig"
                        :navigationControl="true"
                        :oneLocation="true"
                        :scaleControl="true"
                        :searchControl="false"
                        ref="mapLocations"
                        :fullScreenControl="false"
                        v-on:areaChange="setDataFormLocations($event)"
                        :confStyle="'satellite-v9'"
                        :mapHeight="45"
                        v-on:loadComplete="initializeMap(item)"
                        v-on:styleLoad="reload"
                        :actionStyle="false"
                      />
                    </div>
                  </td>
                </template>

                <template v-slot:item.storageDeviceType='{ item }'>
                  {{ translate(item.storageDeviceType.language_key) }}
                </template>

                <template v-slot:item.measurementUnit='{ item }'>
                  {{ translate(item.measurementUnit.languageKey) }}
                </template>

                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="neuter"
                        small
                        class="mr-2"
                        @click="showVersion(item)"
                        v-if="check([{ domain: 'Location', permisions: ['Read'] }])"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </template>
                    <span>
                            {{ $t("common.buttons.show") }}
                            <span style="font-size: 12px; color: #AAAAAA">
                              {{ $t("locations.titles.locations") }}
                            </span>
                          </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="check([{ domain: 'Storage', permisions: ['Update'] }]) && !item.isCurrentVersion"
                        color="neuter"
                        class="mr-2"
                        small
                        @click="editVersion(item)"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>
                            {{ $t("common.buttons.edit") }}
                            <span style="font-size: 12px; color: #AAAAAA">
                              {{ $t("locations.titles.locations") }}
                            </span>
                          </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-6">
          <v-spacer />
          <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="closeVersionsDialog()">
            {{ $t("common.buttons.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import { mapActions, mapGetters } from "vuex"
  import LanguageService from "@/services/LanguajeService"
  import MapBox from "@/components/vueMapbox/MapBox.vue"
  import { mapboxMixin } from "@/mixins/mapbox.mixin"
  import LanguajeService from "@/services/LanguajeService";
  import PermisionService from "@/services/PermisionService";

  export default {
    name: "VersionsComponent",
    components: {
      MapBox,
    },

    props: {
      dialog: {
        type: Boolean,
        required: true,
        default: false,
      },
    },

    mixins: [mapboxMixin],

    data: () => ({
      options: {},
      ready: {},
      loading: false,
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      controls: {
        polygon: false,
        point: false,
        trash: false,
      },
      editedItem: {},
      selectedItem: {},
      defaultFilters: {
        stringFilter: null,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'versionNumber',
          direction: 'ASC',
        }
      },
      storage: null
    }),

    watch: {
      options: {
        async handler() {
          this.loading = true;

          await this.fetchListVersions([{
            locationId: this.storage.storageDeviceId,
            pageableDTO: {
              page: this.options.page - 1,
              size: this.options.itemsPerPage,
              sortBy: 'versionNumber',
              direction: 'ASC',
            }
          }, this.$toast]).finally(() => this.loading = false)
        },
        deep: true,
      },
    },

    computed: {
      ...mapGetters({
        totalFromServer: "storage/totalFromServerVersions",
      }),
      versions: {
        get() {
          return this.$store.getters["storage/versions"]
        },
        set(val) {
          this.$store.commit("storage/SET_VERSIONS_LIST", val)
        },
      },
      headers: () => [
        {
          text: i18n.t("storage.fields.name"),
          value: "control_label",
          width: 250,
          sortable: false,
        },
        {
          text: i18n.t("storage.fields.location"),
          value: "primaryLocation.name",
          width: 250,
          sortable: false,
        },
        {
          text: i18n.t("storage.fields.storage_type"),
          value: "storageDeviceType",
          width: 120,
          sortable: false,
        },
        { text: i18n.t("storage.fields.gln"), value: "gln" },
        {
          text: i18n.t("storage.fields.area"),
          value: "area",
          width: 100,
          sortable: false,
        },
        {
          text: i18n.t("storage.fields.measurement_unit"),
          value: "measurementUnit",
          width: 250,
          sortable: false,
        },
        {
          text: i18n.t("storageType.fields.active"),
          value: "active",
          align: "center",
          width: 100,
        },
        {
          text: i18n.t('common.fields.action'),
          value: 'action',
          sortable: false,
          align: 'start'
        },
        { text: "", value: "data-table-expand" },
      ],
    },

    mounted() {
      this.$root.$on('storage:versions', (item) => {
        this.storage = Object.assign({}, item);
      })
    },

    methods: {
      ...mapActions({
        fetchStorageById: "storage/fetchStorageById",
        fetchListVersions: 'storage/fetchListVersions',
      }),
      showVersion(item) {
        localStorage.setItem('storage_editOrSave', item.id)
        localStorage.setItem('storage:isVersion', JSON.stringify(1));
        this.$router.push({ name: 'storage-management', params: { uuid: item.id, action: 'show' } })
      },
      async editVersion(item) {
        localStorage.setItem('storage_editOrSave', item.id)
        localStorage.setItem('storage:isVersion', JSON.stringify(1));
        this.$router.push({ name: 'storage-management', params: { uuid: item.id, action: 'edit' } })
      },
      check(permisions) {
        return PermisionService.check(permisions);
      },
      closeVersionsDialog() {
        this.$emit("close:dialog", false)
        this.storage = null;
        this.$store.commit("storage/RESTORE_VERSIONS_DATA")
      },
      async loadVersion(e) {
        if (e) {
          if (e.value) {
            this.$set(this.ready, e.item.id, false)

            this.selectedItem = await this.fetchStorageById([
              e.item.id,
              this.$toast,
            ])

            this.editedIndex = this.versions.indexOf(e.item)
            this.editedItem = Object.assign({}, this.selectedItem)
            this.editedItem.description_name = LanguageService.getKey3(
              this.editedItem.description
            )
          } else {
            this.editedItem = {}
            this.selectedItem = {}
            this.$set(this.ready, e.item.id, true)
          }
        }
      },
      async initializeMap(item) {
        await this.reload(true)
        this.$set(this.ready, item.id, true)
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
    },

    destroyed() {
      this.$root.$off('storage:versions');
      this.closeVersionsDialog();
    }
  }
</script>

<style scoped>
  .map {
    height: 300px;
    overflow: hidden;
    position: relative;
  }

  .map img {
    position: absolute; /* Posiciona la imagen de forma absoluta */
    top: 50%; /* Centra la imagen verticalmente */
    left: 50%; /* Centra la imagen horizontalmente */
    width: 110%; /* Aumenta el ancho */
    height: 110%; /* Aumenta el alto */
    object-fit: cover;
    transform: translate(-50%, -50%); /* Desplaza la imagen para centrarla */
  }
</style>
